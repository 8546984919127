export default [
  {
    header: 'Contact Us',
    items: [
      {
        label: 'email',
        value: 'info@pinisi-elektra.com'
      },
      {
        label: 'address',
        value: 'Royal View Residence Mediterania St. 32 Ciwaruga\nBandung, Indonesia'
      },
      {
        label: 'phone',
        value: '+62 22 200 300 4'
      }
    ],
  },
  {
    header: 'Products',
    items: [
      {
        path: '/',
        label: 'Transaction Monitoring Device ',
      },
      {
        path: '/',
        label: 'TMD Spark',
      }
      ,
      {
        path: '/',
        label: 'TimWOK',
      }
    ],
  },
  {
    header: 'Links',
    items: [
      {
        path: '#about',
        label: 'News & Update',
      },
      {
        path: '#career',
        label: 'Career',
      },
      {
        path: '#privacy-policy',
        label: 'Privacy & Policy',
      },
    ],
  },
];

/** @jsx jsx */
import { jsx, Heading, Box, Container, Text, Flex } from 'theme-ui';
import { Link } from 'components/link';
import menuItems from './footer.data';
import { FaGlobe, FaPhone, FaEnvelope } from 'react-icons/fa';

export default function Footer() {
  return (
    <footer sx={styles.footer}>
      <Container sx={styles.footer.container}>
        <Box sx={styles.footer.footerTopArea}>
          {menuItems.map(({ header, items }, i) => (
            <Box sx={styles.footer.menus} key={i}>
              <Heading sx={styles.footer.heading}>{header}</Heading>
              <nav>
                {items.map(({ path, label, value }, i) => {
                  if(path) {
                      return <Link
                      path={path}
                      key={i}
                      label={label}
                      sx={styles.footer.link}
                    />
                  }
                  else {
                    return (
                    <Flex key={i} sx={styles.footer.container}>
                      {label === "email" ? <FaEnvelope sx={styles.footer.icon}/> : ''}
                      {label === "address" ? <FaGlobe sx={styles.footer.icon}/> : ''}
                      {label === "phone" ? <FaPhone sx={styles.footer.icon}/> : ''}
                      <Text sx={styles.footer.text}>{value}</Text>
                    </Flex>
                    )
                  }
                })}
              </nav>
            </Box>
          ))}
        </Box>
      </Container>
      <Text sx={styles.footer.tagline}>
        #KreasiAnakNegeri
      </Text>
      <Text sx={styles.footer.copyright}>
        &copy; 2021 PT. Pinisi Elektra &mdash; All Rights Reserved
      </Text>
    </footer>
  );
}

const styles = {
  footer: {
    container: {
      width: '100%',
      alignItems: 'stretch',
      justifyContent: 'flex-start',
      // alignItems: 'center',
      mb: 5,
      flexDirection: ['column', 'row'],
    },
    footerTopArea: {
      borderTop: '1px solid',
      borderTopColor: 'border_color',
      display: 'flex',
      flexWrap: 'wrap',
      pt: [7, null, 8],
      pb: ['10px', null, null, '20px'],
      px: [0, null, null, null, 4],
    },
    menus: {
      width: ['100%', null, null, '33.33%'],
      display: 'flex',
      flexDirection: 'column',
      mb: ['40px', null, null, '20px'],
    },
    heading: {
      fontSize: [3, null, null, 4],
      color: 'text_secondary',
      fontWeight: '500',
      mb: [3, 4, 5, null, 6],
      lineHeight: '1.35',
      textAlign: 'center',
    },
    text: {
      fontSize: ['14px', null, 1],
      color: 'text',
      textAlign: ['center', 'left'],
      fontWeight: 'body',
      transition: 'all 0.35s',
      display: 'block',
      textDecoration: 'none',
      lineHeight: [1.5, null, null, 1.6, 1.8],
      ':last-child': {
        mb: '0px',
      },
    },
    icon: {
      minWidth: 20,
      minHeight: 20,
      fontSize: ['14px', null, '20px'],
      color: 'primary',
      mr: 2,
      mb: [3, 0],
      justifyContent: 'center',
      alignItems: 'center',
      transition: 'all 0.35s',
      display: 'block',
      textDecoration: 'none',
      lineHeight: [1.5, null, null, 1.6, 1.8],
      ':last-child': {
        mb: '0px',
      },
    },
    link: {
      fontSize: ['14px', null, 1],
      color: 'text',
      textAlign: 'center',
      fontWeight: 'body',
      mb: 2,
      cursor: 'pointer',
      transition: 'all 0.35s',
      display: 'block',
      textDecoration: 'none',
      lineHeight: [1.5, null, null, 1.6, 1.8],
      ':hover': {
        color: 'primary',
      },
      ':last-child': {
        mb: '0px',
      },
    },
    copyright: {
      fontSize: ['14px', null, 1],
      width: '100%',
      textAlign: 'center',
      pb: '20px',
      backgroundColor: '#FCFDFE',
      color: 'text',
      a: {
        textDecoration: 'none',
        color: 'inherit',
        pl: 1,
        transition: 'all 0.25s',
        '&:hover': {
          color: 'primary',
        },
      },
    },
    tagline: {
      fontSize: ['14px', null, '20px'],
      width: '100%',
      textAlign: 'center',
      p: 0,
      m: 0,
      color: 'primary',
      fontWeight: 'bold',
    },
  },
};

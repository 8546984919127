/** @jsx jsx */
import { jsx, Container, Flex, Button } from 'theme-ui';
import { keyframes } from '@emotion/core';
import { Link } from 'react-scroll';
import Logo from 'components/logo';
import LogoDark from 'assets/default-small-white.png';
import LogoWhite from 'assets/default-small.png';
import { DrawerProvider } from '../../contexts/drawer/drawer.provider';
import MobileDrawer from './mobile-drawer';
import menuItems from './header.data';
import { FaEnvelope } from 'react-icons/fa';

export default function Header({ className }) {
  const url = typeof window !== 'undefined' ? window.location.pathname : '';
  return (
    <DrawerProvider>
      <header sx={styles.header} className={className} id="header">
        <Container sx={styles.container}>
          <Logo src={className === 'sticky' ? LogoDark : LogoWhite} />

          <Flex as="nav" sx={styles.nav}>
            {url === '/' && menuItems.map(({ path, label }, i) => (
              <Link
                activeClass="active"
                to={path}
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                key={i}
              >
                {label}
              </Link>
            ))}

            {url !== "/" &&
              <Button
                className="backToHome__btn"
                variant="primary"
                aria-label="Back to Home"
                onClick={() => {
                  window.location.href = '/';
                }}
              >
                Back to Home
              </Button>
            }
          </Flex>

          <Button
            className="contact__btn"
            variant="primary"
            aria-label="Contact Us"
            onClick={() => {
              const url = 'mailto:info@pinisi-elektra.com';
              window.open(url, '_blank');
            }}
          >
            <FaEnvelope sx={{ mt: 0, mb: -1, mr: 2, fontSize: 20 }} /> Contact Us
          </Button>

          <MobileDrawer />
        </Container>
      </header>
    </DrawerProvider>
  );
}

const positionAnim = keyframes`
  from {
    position: fixed;
    opacity: 1;
  }
  to {
    position: absolute;
    opacity: 1;
    transition: all 0.4s ease;
  }
`;

const styles = {
  header: {
    color: 'primary',
    fontWeight: 700,
    py: 4,
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: 'transparent',
    transition: 'all 0.5s ease',
    animation: `${positionAnim} 0.4s ease`,
    '.contact__btn': {
      flexShrink: 0,
      mr: [15, 20, null, null, 0],
      ml: ['auto', null, null, null, 0],
      '&:hover': {
        boxShadow: 'rgba(31, 62, 118, 0.57) 0px 9px 20px -5px',
        backgroundColor: 'secondary',
        color: 'white',
      },
    },
    '&.sticky': {
      position: 'fixed',
      backgroundColor: 'primary',
      color: '#FFF',
      boxShadow: '0 1px 2px rgba(0, 0, 0, 0.06)',
      py: 3,
      'nev > a': {
        color: 'text',
      },
      '.contact__btn': {
        backgroundColor: 'secondary',
        color: 'white',
        '&:hover': {
          boxShadow: 'rgba(31, 62, 118, 0.57) 0px 9px 20px -5px',
          backgroundColor: 'secondary',
          color: 'white',
        },
      },
      '.backToHome__btn': {
        backgroundColor: 'secondary',
        color: 'white',
        '&:hover': {
          boxShadow: 'rgba(31, 62, 118, 0.57) 0px 9px 20px -5px',
          backgroundColor: 'secondary',
          color: 'white',
        },
      },
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    my: 1,
  },
  nav: {
    mx: 'auto',
    display: 'none',
    '@media screen and (min-width: 1024px)': {
      display: 'block',
    },
    a: {
      fontSize: '17px',
      fontWeight: '600',
      px: 20,
      cursor: 'pointer',
      lineHeight: '1.2',
      '&.active': {
        color: 'accent',
      },
    },
  },
};

export default [
  {
    path: 'about',
    label: 'About',
  },
  {
    path: 'our-specialization',
    label: 'Our Specialization',
  },
  {
    path: 'client',
    label: 'Our Client',
  },
  {
    path: 'news',
    label: 'News & Update',
  },
];
